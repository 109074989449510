<script setup lang="ts">
import { differenceInMilliseconds } from 'date-fns';

const props = defineProps({
    durationMs: { Type: Number, default: 200 }
})
const { durationMs } = toRefs(props)

const raf = window.requestAnimationFrame
const nextFrame = (fn: any) => raf((frame) => {
    fn()
    nextFrame(fn)
})

const getDimension = (start: number, end: number, elapsedTime: number, totalTime: number) => {
    const difference = Math.abs(start - end)
    const timePercentage = elapsedTime / totalTime

    const currentDimension = Math.round(difference * timePercentage)

    if (start > end) {
        return start - currentDimension
    }

    return start + currentDimension
}

const animateDimension = (target: HTMLElement, msDuration: number, start: number, end: number) => {
    const startingMs = new Date()
    const prevOverflow = target.style.overflow
    const prevTextOverflow = target.style.textOverflow
    const prevWhiteSpace = target.style.whiteSpace
    target.style.overflow = "hidden"
    target.style.textOverflow = "ellipsis"
    target.style.whiteSpace = "nowrap"

    const animator = () => raf(() => {
        const elapsedMs = Math.min(
            differenceInMilliseconds(new Date(), startingMs),
            msDuration
        )

        const height = getDimension(start, end, elapsedMs, msDuration)
        target.style.height = height + "px"

        if (elapsedMs < msDuration) {
            animator()
        } else {
            if (end == 0) {
                target.style.height = "none"
                return
            }
            target.style.overflow = prevOverflow
            target.style.textOverflow = prevTextOverflow
            target.style.whiteSpace = prevWhiteSpace
            target.style.height = "auto"
            return

        }
    })

    animator()
}

const enter = (target: HTMLElement) => animateDimension(target, durationMs.value, 0, target.clientHeight)
const leave = (target: HTMLElement) => animateDimension(target, durationMs.value, target.clientHeight, 0)

</script>

<template>
    <Transition @enter="enter" @leave="leave" :duration="durationMs">
        <slot></slot>
    </Transition>
</template>

